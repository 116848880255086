
// Import JQuery
// import "jquery/dist/jquery.min.js";

// Import Bootstrap JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Import Lozad
import "lozad/dist/lozad.min.js";

//Get the button
let mybutton = document.getElementById("btn-back-to-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (
    document.body.scrollTop > 20 ||
    document.documentElement.scrollTop > 20
  ) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}
// When the user clicks on the button, scroll to the top of the document
mybutton.addEventListener("click", backToTop);

function backToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

// import "rellax/rellax.min.js";

// Also can pass in optional settings block
// var rellax = new Rellax('.rellax');

// parallax https://github.com/piersrueb/simpleparallax

// const simpleParallax = (elem, modifier) => {
//   let paras = document.getElementsByClassName(elem);
//   for (let i = 0; i < paras.length; i++) {
//     paras[i].setAttribute(
//       "style",
//       "background-repeat: no-repeat; background-attachment: fixed; background-size: cover; background-position: center center;"
//     );
//   }
//   const sp = () => {
//     for (let i = 0; i < paras.length; i++) {
//       let x = paras[i].getBoundingClientRect().top / modifier;
//       let y = Math.round(x * 100) / 100;
//       paras[i].style.backgroundPosition = "center " + y + "px";
//     }
//     requestAnimationFrame(sp);
//   };
//   requestAnimationFrame(sp);
// };

// simpleParallax("para", 8);



// lazyload
// const observer = lozad(); // lazy loads elements with default selector as '.lozad'
// observer.observe();

// form label

const FloatLabel = (() => {
  // Add active class and placeholder attribute when input field is focused
  const handleFocus = (e) => {
    const target = e.target;
    target.closest('.float-field').classList.add('active'); // Target the closest ancestor with class 'float-field'
    target.closest('.float-field').querySelector('label').classList.add('active'); // Also add 'active' class to the label
    // target.setAttribute('placeholder', target.getAttribute('data-placeholder'));
  };

  // Remove active class when input field loses focus
  const handleBlur = (e) => {
    const target = e.target;
    if (!target.value) {
      target.closest('.float-field').classList.remove('active'); // Target the closest ancestor with class 'float-field'
      target.closest('.float-field').querySelector('label').classList.remove('active'); // Also remove 'active' class from the label
    }
    // target.removeAttribute('placeholder');
  };

  // Register events for input fields within .float-field divs
  const bindEvents = (element) => {
    const floatField = element.querySelector('.wpcf7-form-control');
    floatField.addEventListener('focus', handleFocus);
    floatField.addEventListener('blur', handleBlur);
  };

  // Initialize FloatLabel functionality
  const init = () => {
    const floatContainers = document.querySelectorAll('.float-field');

    floatContainers.forEach((element) => {
      if (element.querySelector('.wpcf7-form-control').value) {
        element.classList.add('active');
        element.querySelector('label').classList.add('active');
      }
      bindEvents(element);
    });
  };

  return {
    init: init
  };
})();

FloatLabel.init();

